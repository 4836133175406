<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">Integration</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="field">
          <div class="tabs is-boxed">
            <ul>
              <li v-for="(companyExternalSetting, index) in companyExternalSettings"
                :key="index"
                :class="{ 'is-active' : activeTabIndex === index }">
                <a @click="activeTabIndex = index">{{ companyExternalSetting.settingName }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="field columns"
          v-if="activeCompanyExternalSetting">
          <div class="column is-half">
            <div class="field"
              v-for="(column, index) in $options.columns.left"
              :key="index">
              <label class="label">{{ activeCompanyExternalSetting[column.label] }}</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input"
                    type="text"
                    v-model="activeCompanyExternalSetting[column.value]"
                    :disabled="requirePasswordGeneration(column) || isDiable(column)">
                </div>

                <p v-if="requirePasswordGeneration(column)"
                  class="control">
                  <button class="button is-primary"
                    @click="randomPassword(32, 6)">
                    Generate
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field"
              v-for="(column, index) in $options.columns.right"
              :key="index">
              <label class="label">{{ activeCompanyExternalSetting[column.label] }}
                <span v-if="isEstimageSettingPage && activeCompanyExternalSetting[column.label] === 'Enable Estimage Online'"
                  class="has-text-weight-normal is-italic is-size-7">
                  (Note:
                  <span v-if="$userInfo.isSupportUser"><span class="has-text-weight-bold">2</span> = Test, </span>
                  <span class="has-text-weight-bold">1</span> = On and <span class="has-text-weight-bold">0</span> = Off)
                </span>
              </label>
              <input class="input"
                type="text"
                v-model="activeCompanyExternalSetting[column.value]"
                :disabled="requirePasswordGeneration(column) || isDiable(column)">
            </div>
          </div>
        </div>
        <div class="columns"
          v-if="isEstimageSettingPage">
          <div class="column is-half">
            <div class="flex-row">
              <div class="mr-4">
                <button class="button is-primary"
                  @click="connectEstimage(false)">
                  <span class="icon">
                    <i class="mdi mdi-cached mdi-18px" />
                  </span>
                  <span><span v-if="activeCompanyExternalSetting.property4 === '1'">Re-</span>Connect to Estimage</span>
                </button>
                <span v-if="activeCompanyExternalSetting.property4 === '1'"
                  class="help is-italic has-text-grey">Last connected: {{ $filters.formatDateTimeLocale(dateTimeToLocal(activeCompanyExternalSetting.modifiedDate), $userInfo.locale) }} </span>
              </div>
              <div class="mr-4"
                v-if="$userInfo.isSupportUser">
                <button class="button is-primary"
                  @click="connectEstimage(true)">
                  <span class="icon">
                    <i class="mdi mdi-cached mdi-18px" />
                  </span>
                  <span><span v-if="activeCompanyExternalSetting.property4 === '2'">Re-</span>Connect to Test Estimage</span>
                </button>
                <span v-if="activeCompanyExternalSetting.property4 === '2'"
                  class="help is-italic has-text-grey">Last connected: {{ $filters.formatDateTimeLocale(dateTimeToLocal(activeCompanyExternalSetting.modifiedDate), $userInfo.locale) }} </span>
              </div>
            </div>
          </div>
          <div class="column is-half is-flex is-justify-content-right is-align-items-center">
            <span class="icon is-small has-text-primary mr-1">
              <i class="mdi mdi-18px mdi-information-outline" />
            </span>
            <span class="help is-primary is-italic">Reconnection may periodically be required.</span>
          </div>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import StoreMixin from './storeMixin'
import { DeepDiff } from 'deep-diff'
import { ExternalSettingTypes } from '@/enums'
import { EstImageService } from '@/services'

export default {
  name: 'CompanyExternalSetting',
  mixins: [StoreMixin],
  data() {
    return {
      autoUpdate: null,
      companyExternalSettings: null,
      activeTabIndex: 0,
      timeOutId: null,
      lastModified: null
    }
  },
  columns: {
    left: [
      { label: 'tagLabel', value: 'tag' },
      { label: 'usernameLabel', value: 'username' },
      { label: 'passwordLabel', value: 'password' },
      { label: 'servernameLabel', value: 'serverName' },
      { label: 'portLabel', value: 'port' }
      // { label: 'tokenLabel', value: 'token' }
    ],
    right: [
      { label: 'property1Label', value: 'property1' },
      { label: 'property2Label', value: 'property2' },
      { label: 'property3Label', value: 'property3' },
      { label: 'property4Label', value: 'property4' },
      { label: 'property5Label', value: 'property5' }
    ]
  },
  computed: {
    activeCompanyExternalSetting() {
      if (this.companyExternalSettings && this.companyExternalSettings[this.activeTabIndex]) {
        return this.companyExternalSettings[this.activeTabIndex]
      }
      return null
    },
    externalSettingTypes: function () {
      return ExternalSettingTypes
    },
    isEstimageSettingPage() {
      return this.activeCompanyExternalSetting && this.activeCompanyExternalSetting.settingName === this.externalSettingTypes.SETTING_ESTIMAGE
    },
    isEstimageEnabledLabel() {
      return this.activeCompanyExternalSetting && this.activeCompanyExternalSetting[column.label] === this.externalSettingTypes.SETTING_ESTIMAGE
    }
  },
  watch: {
    companyExternalSettings: {
      handler: function (val) {
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
      },
      deep: true
    },
    isEstimageSettingPage(val) {
      if (this.lastModified !== null) {
        if (val) {
          console.log('watch is estimage true')
          this.fetchEstimageSetting()
        } else {
          console.log('watch is estimage false')
          this.stopFetchEstimageSetting()
        }
      }
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companyExternalSettings = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getCompanyExternalSettings' }))
    this.$hideSpinner()
  },
  methods: {
    dateTimeToLocal(datetime) {
      const z = datetime.includes('Z') ? '' : 'Z'
      return datetime + z
    },
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function' && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    disableFetchEstimageSetting() {
      console.log('disable fetch estimage')
      if (typeof this.fetchEstimageSetting === 'function' && typeof this.fetchEstimageSetting.cancel === 'function') {
        this.fetchEstimageSetting.cancel()
      }
      this.fetchEstimageSetting = () => {}
    },
    async fetchEstimageSetting() {
      console.log('Fetching company external settings')
      const settings = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getCompanyExternalSettings' }))
      this.companyExternalSettings[this.activeTabIndex].modifiedDate = settings[this.activeTabIndex].modifiedDate
      this.companyExternalSettings[this.activeTabIndex].property4 = settings[this.activeTabIndex].property4

      if (this.lastModified === this.activeCompanyExternalSetting.modifiedDate) {
        // Schedule the next fetch after 5 seconds (5000 ms)
        if (this.isEstimageSettingPage) {
          this.timeOutId = setTimeout(this.fetchEstimageSetting, 1000 * 5)
          console.log('keep on checking ' + this.timeOutId)
        }
      } else {
        this.lastModified = null
        this.stopFetchEstimageSetting()
      }
    },
    stopFetchEstimageSetting() {
      clearTimeout(this.timeOutId) // Stop the timeout
      this.timeOutId = null
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && (await this.updateExternalSetting(value))
      }, this.debounceDelay)
    },
    randomPassword(length, nonAlphaLength) {
      var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890'
      var nonAlphaChars = '!@#$%^*()_+~|}{[]:;?,./-='
      var pass = ''
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length)
        pass += chars.charAt(i)
      }
      for (var y = 0; y < nonAlphaLength; y++) {
        var n = Math.floor(Math.random() * nonAlphaChars.length)
        var pos = Math.floor(Math.random() * pass.length)
        pass = pass.substring(0, pos) + nonAlphaChars.charAt(n) + pass.substring(pos + nonAlphaChars.charAt(n).length)
      }
      this.activeCompanyExternalSetting['password'] = pass
    },
    requirePasswordGeneration(column) {
      const integrations = ['BodyNet', 'WebTrim', 'Planning Plus']
      return column.value == 'password' && integrations.some((i) => i == this.activeCompanyExternalSetting['settingName'])
    },
    isDiable(column) {
      const integrations = ['Estimage']
      return column.value == 'property4' && integrations.some((i) => i == this.activeCompanyExternalSetting['settingName'])
    },
    async updateExternalSetting(externalSetting) {
      if (!this.snapshotDiff) return
      for (let item of externalSetting) {
        const [baseItem] = this.baseSnapshot.filter((i) => i.companyExternalSettingId == item.companyExternalSettingId)
        const diff = DeepDiff.diff(baseItem, item)
        item.deepDiff = diff ? JSON.stringify(diff) : null
      }
      this.companyExternalSettings = _cloneDeep(
        await this.editStoreItem({
          serviceFunction: 'updateCompanyExternalSetting',
          entity: externalSetting
        })
      )

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    },
    popupWindow(url, windowName, win, w, h) {
      const y = win.top.outerHeight / 2 + win.top.screenY - h / 2
      const x = win.top.outerWidth / 2 + win.top.screenX - w / 2
      return win.open(
        url,
        windowName,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      )
    },
    async connectEstimage(isTest = false) {
      this.$showSpinner()
      if (this.activeCompanyExternalSetting.username === null || this.activeCompanyExternalSetting.username === '') {
        this.$notification.openNotificationWithType('danger', 'Estimage', 'Username cannot be blank!')
        this.$hideSpinner()
        return false
      }

      if (this.snapshotDiff) {
        await this.updateExternalSetting(this.companyExternalSettings)
      }

      const estImageConfig = await EstImageService.getEstimageConfig()
      const estImageUrl = isTest ? estImageConfig.estImageUrlTest : estImageConfig.estImageUrl
      const estimageClientId = isTest ? estImageConfig.estImageClientIdTest : estImageConfig.estImageClientId
      this.lastModified = this.activeCompanyExternalSetting.modifiedDate
      this.fetchEstimageSetting()

      const url = `${estImageUrl}/oauth/authorize?response_type=code&client_id=${estimageClientId}&redirect_uri=${estImageConfig.estImageRedirectUrl}/auth`

      const w = 800
      const h = 650
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2
      this.$hideSpinner()
      if (!estImageUrl || !estimageClientId || !estImageConfig.estImageRedirectUrl) {
        this.$notification.openNotificationWithType('danger', 'Estimage', 'Could not connect to EstImage. Config is missing.')
      } else {
        return window.open(
          url,
          'Estimage Online Authorisation',
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        )
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.disableFetchEstimageSetting()
    this.toastNotificationEnabled = true
    await this.updateExternalSetting(this.companyExternalSettings)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>

<style lang="scss" scoped>
.tabs ul {
  width: 10em;
}

div.flex-row {
  display: flex;
  flex-direction: row;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #71b3ff;
  border-radius: 10px;

  &:hover {
    background: #3291ef;
  }
}
</style>